<template>
  <div class="container commonDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Mine/OnSale', query: { 'activeName': detailType } }">
        <template v-if="detailType=='1'"> 挂卖管理 </template>
        <template v-if="detailType=='2'"> 卖单详情 </template>
        <template v-if="detailType=='3'"> 买单详情 </template>
      </el-breadcrumb-item>
      <el-breadcrumb-item>挂卖详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="commonWhiteBg">
      <div v-if="detailType=='1'">
        <div class="commonTitle"> 
          挂卖订单详情 
          <el-button type="primary"  size="small"> 停止挂卖 </el-button>
        </div>
        <div class="ant-row topBlocks">
            <div class="ant-col ant-col-md-24 ant-col-lg-12 ant-col-xl-6">
                <div class="block">
                    <p class="tittle">挂卖金额</p>
                    <p class="num">100</p>
                </div>
            </div>
              <div class="ant-col ant-col-md-24 ant-col-lg-12 ant-col-xl-6">
                <div class="block">
                    <p class="tittle">今日交易份数</p>
                    <p class="num">10</p>
                </div>
            </div>
              <div class="ant-col ant-col-md-24 ant-col-lg-12 ant-col-xl-6">
                <div class="block">
                    <p class="tittle">累计交易份数</p>
                    <p class="num green">10</p>
                </div>
            </div>
              <div class="ant-col ant-col-md-24 ant-col-lg-12 ant-col-xl-6">
                <div class="block">
                    <p class="tittle">累计收益</p>
                    <p class="num">999</p>
                </div>
            </div>
        </div>
        <div class="tips">
          累计收益=挂卖金额*0.9*交易份数 
            <a-tooltip>
              <template slot="title">
                prompt text
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
        </div>
        <el-form
          ref="form"
          :model="form"
          label-width="140px"
        >
          <el-form-item
            label="存证文件"
            prop="value1"
          >
            {{form.value1}}
            <router-link
             class="ml8"
              :to="{path:'/ExistingEvidence/FileList',query: {'orderNo':''}}"
            >
              查看存证文件
            </router-link>
          </el-form-item>
          <el-form-item
            label="挂卖标题"
            prop="value2"
          >
            {{form.value2}}
              <router-link
              class="ml8"
              :to="{path:'/ExistingEvidence/FileList',query: {'orderNo':''}}"
            >
              查看取证文件
            </router-link>
          </el-form-item>
          <el-form-item
            label="订单号"
            prop="value3"
          >
            {{form.value3}}
          </el-form-item>
            <el-form-item
            label="挂卖金额"
            prop="value4"
          >
            {{form.value4}} 元
          </el-form-item>
           <el-form-item
            label="状态"
            prop="value5"
          >
            {{form.value5}}
          </el-form-item>
           <el-form-item
            label="创建时间"
            prop="value6"
          >
            {{form.value6}} 
          </el-form-item>
        </el-form>
      </div>
      <div v-if="detailType=='2'">
        <div class="commonTitle"> 卖单详情 
          <router-link :to="{path:'/OnSale/DetailView'}">
            <el-button type="primary"  size="small"> 挂卖详情 </el-button>
          </router-link> 
        </div>
         <el-form
          ref="form"
          :model="form"
          label-width="140px"
        >
          <el-form-item
            label="存证文件"
            prop="value1"
          >
            {{form.value1}}
            <router-link
             class="ml8"
              :to="{path:'/ExistingEvidence/FileList',query: {'orderNo':''}}"
            >
              查看存证文件
            </router-link>
          </el-form-item>
          <el-form-item
            label="挂卖标题"
            prop="value2"
          >
            {{form.value2}}
              <router-link
              class="ml8"
              :to="{path:'/ExistingEvidence/FileList',query: {'orderNo':''}}"
            >
              查看取证文件
            </router-link>
          </el-form-item>
          <el-form-item
            label="订单号"
            prop="value3"
          >
            {{form.value3}}
          </el-form-item>
            <el-form-item
            label="挂卖金额"
            prop="value4"
          >
            {{form.value4}} 元
          </el-form-item>
            <el-form-item
            label="平台手续费(1%)"
            prop="value7"
          >
            {{form.value7}} 元
          </el-form-item>
           <el-form-item
            label="收益金额"
            prop="value8"
          >
            {{form.value8}} 元
          </el-form-item>
           <el-form-item
            label="交易时间"
            prop="value6"
          >
            {{form.value6}} 
          </el-form-item>
           <el-form-item
            label="当前状态"
            prop="value9"
          >
            {{form.value9}}
          </el-form-item>
              <el-form-item
            label="支付方"
            prop="value10"
          >
            {{form.value10}}
          </el-form-item>
        </el-form>
      </div>
      <div v-if="detailType=='3'">
       <div class="commonTitle"> 买单详情 
          <router-link :to="{path:'/OnSale/DetailView'}">
            <el-button type="primary" size="small"> 挂卖详情 </el-button>
          </router-link> 
        </div>
         <el-form
          ref="form"
          :model="form"
          label-width="140px"
        >
          <el-form-item
            label="存证文件"
            prop="value1"
          >
            {{form.value1}}
            <router-link
             class="ml8"
              :to="{path:'/ExistingEvidence/FileList',query: {'orderNo':''}}"
            >
              查看存证文件
            </router-link>
          </el-form-item>
          <el-form-item
            label="挂卖标题"
            prop="value2"
          >
            {{form.value2}}
              <router-link
              class="ml8"
              :to="{path:'/ExistingEvidence/FileList',query: {'orderNo':''}}"
            >
              查看取证文件
            </router-link>
          </el-form-item>
          <el-form-item
            label="订单号"
            prop="value3"
          >
            {{form.value3}}
          </el-form-item>
            <el-form-item
            label="支付金额"
            prop="value4"
          >
            {{form.value4}} 元
          </el-form-item>
          <el-form-item
            label="支付方式"
            prop="value11"
          >
            {{form.value11}}
          </el-form-item>
          <el-form-item
            label="首款方"
            prop="value10"
          >
            {{form.value10}}
          </el-form-item>
           <el-form-item
            label="交易时间"
            prop="value6"
          >
            {{form.value6}} 
          </el-form-item> 
        </el-form>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'MySaleDetail',
  components: {},
  data () {
    return {
      detailType: '1',
      form: {
        value1: '作品名称123',
        value2: '作品取证名称123',
        value3: '1208130281038021830808',
        value4: '100',
        value5:'挂卖中',
        value6:'2019-12-23 14:07',
        value7:'1',
        value8:'99',
        value9:'已存入钱包',
        value10:'张三',
        value11:'微信支付'
      }
    }
  },
  mounted () {
    const tempDetailType = this.$route.query.detailType
    if (!!tempDetailType) {
      this.detailType = tempDetailType
    }
  },
  methods: {
  }
}
</script>

<style lang='scss' scoped>
.topBlocks{
  margin-bottom: 16px;
  .block{
      margin:16px;
      padding:16px;
      text-align: center;
      box-shadow: 0 0 6px rgba(0, 109, 117,.1);
      color:rgba($color: #000000, $alpha: .85);
      .num{
        font-size: 18px;
        margin-top:8px;
        font-weight: bolder;
      }
      .num.green{
         color:#007DFF;
      }
      .tittle{
        font-size: 12px;
      }
  }
}
.tips{
  font-size: 12px;
  color:rgba($color: #001216, $alpha: .5);
  padding-left: 24px;
}
.commonDetail /deep/ .el-form{
  margin-top:24px;
  padding-left: 32px;
  padding-bottom: 54px;
}
/deep/ .el-button--primary, .el-button--primary:focus, .el-button--primary:visited{
  background: #fff;
  color:#007DFF;
}
</style>
